// LOGO SLIDER

if(document.getElementById('actions-slider')){
    $('#actions-slider').slick({
        infinite: false,
        slidesToShow: 1,
        slidesToScroll: 1,
        padding: 10,
        mobileFirst: true,
        margin: 10,
        centerMode : false,
        autoplay: true,
        autoplaySpeed: 3000,
        dots: false,
        prevArrow: $('.prev'),
        nextArrow: $('.next'),
        responsive: [
            {
              breakpoint: 991,
              settings: {
                slidesToShow: 2,
                slidesToScroll: 2,
              }
            },

            {
              breakpoint: 1200,
              settings: {
                slidesToShow: 3,
                slidesToScroll: 3
              }
            }
        ]
    });
}